import { JobStatus } from '@qureos/types'

export const getStatusColorScheme = (status: JobStatus) => {
  switch (status) {
    case JobStatus.Approved: {
      return 'bg-green-50 text-green-400'
    }
    case JobStatus.Closed:
    case JobStatus.Rejected: {
      return 'bg-red-50 text-red-400'
    }
    case JobStatus.Pending: {
      return 'bg-yellow-50 text-yellow-400'
    }
    case JobStatus.Deleted:
    case JobStatus.Draft: {
      return 'bg-gray-50 text-gray-400'
    }
  }
}
