import { CheckboxOptionType } from 'antd'
import { Company, Skill, ThemeType } from '..'
import { LabeledValue } from 'antd/lib/select'
import { CandidateInvitationStatus } from '@qureos/statics/job-application'
import { Apprentices } from '../apprentices'

export enum JobType {
  OnSite = 'ON_SITE',
  Remote = 'REMOTE',
  Hybrid = 'HYBRID'
}

export enum JobSite {
  'IN_PERSON' = 'In-person',
  'ON_SITE' = 'On-site',
  'REMOTE' = 'Remote',
  'HYBRID' = 'Hybrid'
}

export enum JobSiteRequestDto {
  'On-site' = 'ON_SITE',
  'Remote' = 'REMOTE',
  'Hybrid' = 'HYBRID'
}

export enum JobContractType {
  'FULL_TIME' = 'Full time',
  'PART_TIME' = 'Part time',
  'CONTRACT' = 'Contract',
  'INTERN' = 'Intern',
  'FREELANCER' = 'Freelancer',
  'APPRENTICESHIP' = 'Apprenticeship',
  'CO_OP' = 'Co-op'
}

export enum JobContracRequestDto {
  'Full time' = 'FULL_TIME',
  'Part time' = 'PART_TIME',
  'Contract' = 'CONTRACT',
  'Intern' = 'INTERN',
  'Freelancer' = 'FREELANCER',
  'Apprenticeship' = 'APPRENTICESHIP',
  'Co-op' = 'CO_OP'
}

export enum JobCandidateAvailability {
  summer = 'summer',
  fall = 'fall',
  winter = 'winter',
  spring = 'spring',
  asap = 'asap'
}

export enum WorkStyleConversion {
  'on-site' = 'IN_PERSON',
  'remote' = 'REMOTE',
  'hybrid' = 'HYBRID'
}

export enum ContractTypeConversion {
  'full-time' = 'FULL_TIME',
  'part-time' = 'PART_TIME'
}

export interface PayScale {
  min: number
  max: number
  unit: string
  isHidden?: boolean
  frequency?: string
}

export interface ResourceProperty {
  title: string
  name: string
  url: string
  createdAt: Date
}

export interface ScreeningQuestion {
  id?: number
  question: string
  idealAnswer?: string
}

export enum JobStatus {
  'Pending' = 'PENDING',
  'Rejected' = 'REJECTED',
  'Approved' = 'APPROVED',
  'Closed' = 'CLOSED',
  'Draft' = 'DRAFT',
  'Deleted' = 'DELETED'
}

export enum JobClosureReason {
  OTHER = 'other',
  HIRED_SOMEONE = 'hiredSomeone',
  HIRED_FROM_SOMEWHERE_ELSE = 'hireFromSomewhereElse',
  COULD_NOT_HIRE = 'couldNotHire',
  EXPIRED = 'expired',
  NO_NEED = 'noNeed'
}

export const JobClosureReasonToTextMapping: Record<string, string> = {
  [JobClosureReason.HIRED_SOMEONE]: 'I hired a candidate from Qureos',
  [JobClosureReason.HIRED_FROM_SOMEWHERE_ELSE]:
    'I hired a candidate from Other sources',
  [JobClosureReason.COULD_NOT_HIRE]:
    "I couldn't find relevant candidates for this position",
  [JobClosureReason.NO_NEED]: "I don't need to hire for this position anymore",
  [JobClosureReason.OTHER]: 'Other'
}

export enum JobClosureReasonReverse {
  'I hired a candidate from Qureos' = JobClosureReason.HIRED_SOMEONE,
  'I hired a candidate from Other sources' = JobClosureReason.HIRED_FROM_SOMEWHERE_ELSE,
  "I couldn't find relevant candidates for this position" = JobClosureReason.COULD_NOT_HIRE,
  "I don't need to hire for this position anymore" = JobClosureReason.NO_NEED,
  'Other' = JobClosureReason.OTHER
}

export const statusToTextMappingJob: Record<string, string> = {
  [JobStatus.Pending]: 'Pending For Approval',
  [JobStatus.Rejected]: 'Rejected',
  [JobStatus.Approved]: 'Active',
  [JobStatus.Closed]: 'Closed',
  [JobStatus.Draft]: 'Draft',
  [JobStatus.Deleted]: 'Archived'
}

export enum RemoteCulturesEnum {
  'InPerson' = 'IN_PERSON',
  'remoteDistributed' = 'REMOTE_DISTRIBUTED'
}

export interface Companies {
  _id: string
  name: string
  slug?: string
}

export interface PartialJobResponse {
  _id: string
  title: string
}
export interface JobResponse extends PartialJobResponse {
  createdDateTime: Date
  contractType: string
  description: string
  caseStudy: string
  location: string
  locations?: {
    location?: string
  }[]
  city?: string
  country?: string
  visible: boolean
  status: JobStatus
  closeDate: string
  company: Partial<Company>
  theme?: ThemeType
  expiresIn?: number
  site: string
  isPublic?: boolean
  isExternal?: boolean
  skills?: Skill[]
  applyLink?: string
  linkedinLink?: string
  workExperience?: number
  allRemoteLocations?: boolean
  remoteCulture?: RemoteCulturesEnum
  remoteLocationPreferences?: string[]
  workStyle?: string
  workStyles?: string[]
  board?: string | string[]
  slug?: string
  data?: any
  createdBy?: {
    firstName: string
    lastName: string
    email: string
    _id: string
  }
  isConfidential?: boolean
  candidateUpdatesCount?: number
}

export enum AiInterviewStatus {
  Pending = 'PENDING',
  Invited = 'INVITED',
  InProgress = 'IN_PROGRESS',
  InterviewTaken = 'TAKEN',
  InterviewCompleted = 'COMPLETED',
  Cancelled = 'CANCELLED',
  Errored = 'ERRORED'
}

export interface JobsMeta {
  themes: ThemeType[]
  contractTypes: string[]
  locations: string[]
  companies: Companies[]
  themeLocationPairs: [string[]]
  activeJobsSlugs?: string[]
}

export type RecommendedApprentice = {
  _id: string
  firstName: string
  lastName: string
  email: string
  profileHeadline?: string
  photo?: string
  location?: string
  profile?: Apprentices
}

export enum ExclusionTypeEnum {
  /* corporate types */
  NOT_A_FIT = 'NOT_A_FIT', // Candidate doesn't meet the required criteria
  NOT_INTERESTED = 'NOT_INTERESTED', // User is not interested in the candidate
  NO_RESPONSE = 'NO_RESPONSE', // Candidate hasn't responded to communication attempts
  NOT_RELEVANT = 'NOT_RELEVANT', // Candidate's skills or experience don't align with the job
  OTHER = 'OTHER', // User wants to provide their own reason

  /* admin type */
  ADMIN_REJECTED = 'ADMIN_REJECTED', // Candidate was rejected from Admin side

  /* candidate types */
  DECLINED = 'DECLINED', // Candidate has declined the invitation
  CANDIDATE_NOT_INTERESTED = 'CANDIDATE_NOT_INTERESTED' // Candidate is not interested (reported by client)
}

export interface Recommendations {
  candidate?: RecommendedApprentice
  exclusionDetails: { excludedAt: string; type: ExclusionTypeEnum }
  isFavorite: boolean
}
export interface CorporateJobResponse extends JobResponse {
  site: string
  payRange: PayScale
  showPayToApprentices: boolean
  isQureosCaseStudy: boolean
  caseStudyNotRequired: boolean
  resources: Partial<ResourceProperty>[]
  screeningQuestions?: ScreeningQuestion[]
  recommendations?: Recommendations[]
  rejectionCount?: number
  candidateAvailability?: string
  updatedDateTime?: string
}

export interface OutreachResponse {
  content: string
  status: CandidateInvitationStatus
  type: string
  method: string
  createdAt?: string
  updatedAt?: string
  sendAfter?: string
  sendAfterDelay?: number
}

export interface PartialRecommendation {
  _id: string
  jobId: string
  conversationId: string
  candidate: {
    profile: { email?: string; linkedIn: string | undefined }
  }
  outreaches: OutreachResponse[]
}

export interface IrisConversation {
  companyId: string
  createdAt: Date
  job: any
  jobId?: string
  events: any
  generatedJson: any
  company: any
  title: string
  updatedAt: Date
  userId?: string
  _id: string
  rankingConfiguration?: any
  hasAiScreening?: boolean
  screeningCriterias?: { text: string }[]
}

export interface RejectCandidatePayload {
  jobId: string
  candidateIds: string[]
  type: string
  reason?: string
  sendNotification?: boolean
}

export enum PublicJobStatus {
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Closed = 'CLOSED'
}

export interface RecommendationsMetaData {
  genders: CheckboxOptionType[]
  diversities: CheckboxOptionType[]
  languages: CheckboxOptionType[]
  countries: LabeledValue[]
  nationalities?: string[]
  educationDegrees?: LabeledValue[]
  educationFields?: LabeledValue[]
  workHistoryIndustries?: LabeledValue[]
  source?: LabeledValue[]
  selectionMethod?: SelectionMethod[]
}

export enum Gender {
  ALL = 'all',
  MALE = 'male',
  FEMALE = 'female',
  NONBINARY = 'nonBinary',
  PREFERNOTTOSAY = 'preferNotToSay'
}

export enum SelectionMethod {
  ADMIN = 'admin',
  AI = 'ai',
  SELF = 'self'
}

export enum Source {
  QUREOS = 'qureos',
  EXTERNAL = 'external'
}

export enum Diversities {
  MALE = 'male',
  FEMALE = 'female',
  NONBINARY = 'nonBinary',
  PREFERNOTTOSAY = 'preferNotToSay'
}
